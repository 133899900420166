/* FONTS UTILISÉES DANS LE DOC -- à défault de grotesk, je vais utiliser Futura qui s'en approche */
@font-face {
  font-family: "Futura";
  src: url('./Fonts/futura\ light\ bt.ttf') format("ttf");
  font-weight: 200;
  font-style: normal
}
@font-face {
  font-family: "Futura";
  src: url('./Fonts/Futura\ Book\ font.ttf') format("ttf");
  font-weight: 400;
  font-style: normal
}
@font-face {
  font-family: "Futura";
  src: url('./Fonts/futura\ medium\ bt.ttf') format("ttf");
  font-weight: 500;
  font-style: normal
}


body {
  margin: 0;
  font-family: 'Futura', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 200;

}

code {
  font-family:  'Futura', Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  font-weight: 200;
}

* a{
  text-decoration: none;
}


