.Footer{
    overflow: hidden;
}

/* ---- BANNIÈRE QUI DÉFILE ---- */
.rollingBanner{
}

.rollingBanner .item{
    margin: 0 100px;
}

.rollingBanner .Main{
    font-size: 14vw;
}

.rollingBanner .Secondary{
    font-size: 2.2vw;
    font-style: italic;
}

/* ---- DERNIÈRE BANNIÈRE DE PRÉSENTATION ----  */
.finalBanner{
    padding: 40px 17px 30px 17px;
    margin-top: 150px;
}

.finalBanner .Photo{
    grid-column: span 5;
    margin-bottom: 100px;
}

.finalBanner .Photo h2{
    font-weight: 200;
    font-size: 3.2vw;
    margin: 0 0 30px 0;
}

.finalBanner .Photo div{
    width: 50%;
    height:300px;
    overflow:hidden;
    margin-left:70px
}

.finalBanner .Description{
    grid-column: span 7;
}

.finalBanner .Description h2{
    font-weight: 200;
    font-size: 2vw;
    margin: 0 0 20px 0;
}

.finalBanner .Description h2:nth-of-type(2){
    margin-top: 50px;
}

.finalBanner .Description p{
    color: #959595;
    line-height: 1.5;
    font-size: 1.55vw;
}

/* ---- JUSTE POUR TESTER LE SCROLLTRIGGER ---- */
.placeholder{
    width: 100vw;
    height: 100vh;
}