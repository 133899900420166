.flex{
    display: flex
}

.border{
    border: red solid 1px
}

.relative{
    position: relative;
}

.absolute{
    position: absolute;
}

.fixed{
    position: fixed;
}

.resp{
    width: 100%;
}

.grid{
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.hidden{
    display: none;
}

/* Transition de page */
.pageEnter{
    height: 100vh;
    width: 100vw;
    background: #e0dbd5;
    /* border-radius: 50%; */
    /* right: -40px;
    top: -100px; */
}
.pageExit{
    height: 100vh;
    width: 100vw;
    background: #e0dbd5;
    opacity: 0;
    z-index: 20;
    pointer-events: none;
 
}