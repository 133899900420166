.HireMe{
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.HireMe h1{
    font-weight: 200;
    font-size: 7.8vw;
}

.countdown{
    font-size: 3vw;
}