.Navbar{
    width: 100vw; 
    color: white;
}

.Navbar .container{
    justify-content: space-between;
    align-items: center;
    margin: 0 100px;
}

.Navbar .Logo{
    font-weight: 500;
    font-size: 20px;
}

.Navbar .Liens{
    flex-direction: column;
    align-items: flex-end;
    margin: 20px 0;
    width: 48%;    
}

.Navbar .NavLink{
    text-decoration:none;
    color: white;
}

.Navbar .Liens *{
    justify-content: space-between;
    align-items: flex-start;
    margin: 5px 0;
}

.Navbar .Reseaux{
    width: 50%;
    font-size: 14px;
}

.Navbar .Reseaux img{
    width: 13px;
    margin: 0;
}

.Navbar .Navigation{
    width: 100%;
    font-size: 16px;
}

/* TRANSITION DU BLANC AU NOIR POUR LA NAVBAR */
.white{
    color: white;
}

.black{
    color: black
}