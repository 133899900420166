.Awards{
    padding: 150px 0;
    margin: 0 15px;
}

.Awards .paragraph {
    font-size: 4.7vw;
    line-height: 1;
    margin-bottom: 100px;
    width: 100vw;
}

.Awards .paragraph .word{
    display: inline-block;
    margin: 0;
}
.Awards .paragraph .container{
    display: inline-block;
    overflow: hidden;

    margin: 0;
}

/* .keyWord::after{
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: black;
} */