.Cursor {
  mix-blend-mode: difference;
  width: 35px;
  height: 35px;
  border: 1px solid #E0DBD6;
  border-radius: 50%;
  position: absolute;
  transition: ease 0.5s;
  pointer-events: none;
  z-index: 1;
  transition-property: border-color, transform; }

.expand {
  transform: scale(2);
  mix-blend-mode: normal; 
}

.contract {
  border: #1F2BBC solid 1px;
  background-color: #1F2BBC;
  transform: scale(0.3);
  mix-blend-mode: normal; }