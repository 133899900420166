.HeroBanner{
    width: 100vw;
    height: 120vh;
    margin-bottom: 200px;
}

/* HERO TEXT */
#placeholder{
    height: 230px;
}

.Hero_text{
    padding: 230px 15px 0 15px;
    color: white;    

}

.Hero_text h1{
    line-height: 1.3;
    font-size: 6vw;
    font-weight: 100;

}

.Hero_text > p{
    font-size: 1.4vw;
    line-height: 1.5;
    width: 40%;
    margin: 30px auto;
}

/* HERO BANNER FOOTER */
.HeroBanner .Hero_footer{
    color: white;
    margin: 93px 0;
    align-items: center;
}

.Hero_footer p:first-of-type{
    margin-right:27%;
    font-size: 14px;
}

.HeroBanner .Hero_footer a{
    color: white;
    font-weight: 500;
    font-size: 15px;
    position: relative;
}

.HeroBanner .Hero_footer a::after{
    content:' ';
    width: 100%;
    height: 1px;
    background-color: black;
    position: absolute;
    bottom: -2px;
    left:-1px
}

.Ball{
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 50%;
    margin-right: 10px;
}

/* BACKGROUND VIDEO */
.bg_video{
    pointer-events: none;
    width: 100vw;
    height: 150vh;
    z-index: -2;

}

.video_filter{
    width: 100vw;
    height: 150vh;
    background-color: hsla(142, 0%, 0%, 0.6);
}

.video{
    width: 100vw;
    height: 100%;
    object-fit: cover;
    z-index: -200;
}